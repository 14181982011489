export const EN = {

  //WEB__APPLICATION__TEXT_(english)

  //COMMON_TEXT
  bigdata: "BIGDATA",
  welcome: "WELCOME",
  Next: "Next",
  EDIT: "EDIT",
  CREATE: "CREATE",
  RENAME: "Rename",
  Details: "Details",
  Optional: "Optional",
  Start_Date: 'Start Date',
  End_Date: 'End Date',
  Active: "Active",
  write_here: "write here......",
  SUBMIT: "SUBMIT",
  Successfully_Done: "Successfully Done",
  Something_Went_Wrong_Try_Again: "Something Went Wrong, Try Again",
  Your_item_has_been_deleted: "Your item has been deleted",
  Search_Option: "Search Option",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Username: 'Username',
  Email: "Email",
  Mobile: "Mobile",
  New_Password: "New Password",
  Confirm_New_Password: "Confirm New Password",
  Select_one: "Select one",
  Action: "Action",
  type: 'Type',
  total: "Total",
  Back: "Back",
  Update: "Update",
  Birth_Date: "Birth Date",
  Gender: "Gender",
  Phone_Number: "Phone Number",
  Postal_Code: "Postal Code",
  Province: "Province",
  Search: "Search",
  Municipality: "Municipality",
  Address: "Address",
  Total_Family_Member: "Total Family Member",
  Fidelity_Card_Collection: "Fidelity Card Collection",
  FROM_SALE_POINT: "FROM SALE POINT",
  SEND_TO_POSTAL_CODE: "SEND TO POSTAL CODE",
  SUBMIT_YOUR_FILE: "SUBMIT YOUR FILE",
  SELECTED: "SELECTED",
  LAST_FILE_IS_NOT_FULLY_UPLOADED_SOMETHING_WENT_WRONG: "LAST FILE IS NOT FULLY UPLOADED.SOMETHING WENT WRONG",
  Not_exist: "Not exist",
  Field_is_empty: "Field is empty",
  Are_you_sure: "Are you sure?",
  You_wont_be_able_to_revert_this: "You won't be able to revert this!",
  Yes_delete_it: "Yes, delete it!",
  Yes_confirm_it: "Yes, confirm it!",
  Cancel: "Cancel",
  Operation_Successful: "Operation Successful",
  Product_Description: "Product Description",
  Name: "Name",
  REMOVE: "REMOVE",
  Successfully_Done: "Successfully Done",
  OOPS: "OOPS",
  Created_Successfully: "Created Successfully",
  print: "Print",
  print_preview: "Print Preview",
  to: 'TO',
  please_enter_empty_field: "Please, enter the empty field !",
  export: "Export",
  export_all: "Export All",
  exporting: "Exporting...",
  Download: "Download",
  Download_all: "Download All",
  Previous_Exported_File_Download: "Previous Exported File Download",
  LAST_FILE_EXPORT_FAILED: "LAST FILE EXPORT, FAILED!",
  there_is_no_data: "There is no data",

  //Header
  help: "Help",

  //LOGIN/FORGOT_PASS_MODULE/MY ACCOUNT
  login: "Login",
  client_code: "Client Code",
  invalid_client_code: "Invalid client code",
  forgot_password: "forgot password",
  The_Password_Recovery_Questions: "The Password-Recovery Questions",
  Question_No_1: "Question No. 1",
  Question_No_2: "Question No. 2",
  Question_No_3: "Question No. 3",
  Change_Password: "Change Password",
  Password: "Password",
  Confirm_Password: "Confirm Password",
  password_must_be_at_least_6_characters_in_length: "password must be at least 6 characters in length",
  Make_sure_all_fields_are_filled_where_password_and_confirm_password_should_be_matched: "Make sure all fields are filled where password and confirm password should be matched",
  password_mismatched: "password mismatched",
  Old_Password: "Old Password",
  There_is_no_security_questions_you_will_not_be_able_to_recover_your_password_in_future: "There is no security questions, you will not be able to recover your password in future",
  PASSWORD_MISMATCHED_TRY_AGAIN: "PASSWORD MISMATCHED, TRY AGAIN",
  Please_Enter_You_Old_Password: "Please Enter You Old Password",

  Make_sure_all_fields_are_filled: "Make sure all fields are filled",
  Your_Answer: "Your Answer",
  Your_Question: "Your Question",
  Your_Security_Questions_For_Future_Password_Reset_Authorizations: "Your Security Questions For Future Password Reset Authorizations",

  enter_one_time_password: "Please enter the one time password",
  to_verify_your_account: "to verify your account",
  code_has_been_sent: "A code has been sent to your registered Email Account",
  your_code_is_wrong: "Enter the correct code",

  //SIDE_BAR
  home: "Home",
  fidelity_card_request: "Fidelity Card Request",
  fidelity_card: "Fidelity Card",
  fidelity_card_upload: "Fidelity Card Upload",
  fidelity_card_list: "Fidelity Card List",
  product: "Product",
  product_excel_upload: "Product Excel Upload",
  product_list: "Product List",
  product_image_upload: "Product Image Upload",
  product_image_list: "Product Image List",
  product_info: "Product Info",
  product_info_create: "Product Info Create",
  product_info_list: "Product Info List",
  product_orders: "Product Orders",
  last_minute_offer: "Last Minute Offer",
  offer_create: "Offer Create",
  offer_list: "Offer List",
  user_points: "User Points",
  points_list: "Points List",
  points_conversion_list: "Points Conversion List",
  points_conversion_requests: "Points Conversion Requests",
  export_user_points: "Export User Points",
  communication: "Communication",
  send_message: "Send Message",
  message_list: "Message List",
  institutional_flyers: "Institutional Flyers",
  create_flyer: "Create Flyer",
  flyers_list: "Flyers List",
  sales_points: "Sales Points",
  upload_sales_point_info: "Upload Sales Points Info",
  sales_point_list: "Sales Points List",
  delivery_slot: "Delivery Slot",
  sales_points_delivery_slot: "Sales Points Delivery Slot",
  upload_delivery_slots: "Upload Delivery Slots",
  special_delivery_dates: "Special Delivery Dates",
  ticket_management: "Ticket Management",
  news: "News",
  create_news: "Create News",
  news_list: "News List",
  events: "Events",
  create_event: "Create Event",
  event_list: "Event List",
  reviews: "Reviews",
  create_reviews: "Create Reviews",
  reviews_list: "Reviews List",
  advance_profiling: "Advance Profiling",
  create_survey: "Create Survey",
  survey_list: "Survey List",
  notice: "Notice",
  contact: "Contact",
  privacy_agreement: "Privacy Agreement",
  privacy_terms_of_services: "Privacy & Terms Of Services",
  cancellation_requests: "Cancellation Requests",
  manage_user_account: 'Manage Users Account',
  account_delete_request: 'Account Delete Requests',
  settings: "Settings",
  max_items: "Max Items",
  branch_users: 'Branch Users',
  payment_key: "Payment Key",
  admin_mail: "Admin notification email",
  my_account: "My Account",
  change_password: "Change Password",
  security_questions: "Security Questions",
  image_management: "Image Management",


  //FIDELITY_CARD_REQUEST_MODULE
  Fidelity_Card_Requests_List: "Fidelity Card Requests List ",
  Temporary_Username: "Temporary Username",
  Full_Name: "Full Name",
  Fidelity_Card_Request_Details: "Fidelity Card Request Details ",
  SALE_POINT_DETAILS: "SALE POINT DETAILS",
  Sale_Point_Name: "Sale Point Name",
  Sale_Point_Code: "Sale Point Code",
  Sale_Point_Address: "Sale Point Address",
  There_is_no_requests: "There is no requests",
  new_request: "Unseen Requests",

  //FIDELITY_CARD_MODULE
  FIDELITY_CARD_INFO_UPLOAD: "FIDELITY CARD INFO UPLOAD",
  FIDELITY_CARD: "FIDELITY CARD",
  There_is_no_fidelity_card_info: "There is no fidelity card info",
  IDCard: "IDCard",
  TipoProfilaz: "Type Profile",
  Comune_di_Residenza: "City of residence",
  Fidelity_Card_Details: "Fidelity Card Details ",
  EXCEL_UPLOAD_INFO: "EXCEL UPLOAD INFO",
  USER_GIVEN_INFO: "USER GIVEN INFO",
  File_Is_Uploading: "File Is Uploading",
  IDPuntoVendita: "IDPuntoVendita",
  Regione_di_Residenza: "Region of Residence",
  Stato_Fam: "State Fam",
  Num_Figli: "Num Sons",
  Altri_Comp: "Other Comp",
  Spesa_Altri_A: "Expenditure Others (A)",
  Se_SI_quanta_spesa: "If 'YES' how much spending +/-? (B)",
  Verification_Status: "Verification Status",
  Verification: "Verification",
  Verified: "Verified",
  Not_Verified: "Not Verified",
  Verify: "Verify",
  Remove: "Remove",
  Verify_Successful: "Verify Successful",
  Verify_removed_successfully: "Verify removed successfully",
  SEND_MAIL_TO_ALL_UNVERIFIED_USERS: "SEND MAIL TO ALL UNVERIFIED USERS",

  //PRODUCT_MODULE
  Product: "Product",
  PRODUCT_EXCEL_UPLOAD: "PRODUCT EXCEL UPLOAD",
  Product_Details: "Product Details ",
  Product_Image_Upload: "Product Image Upload",
  All_Images: "All Images",
  Upload_Single_Image: "Upload Single Image",
  Delete_All_Image: "Delete All Image",
  View_Product: "View Product",
  TOTAL_IMAGE: "TOTAL IMAGE",
  Edit_Name: "Edit Name",
  Delete: "Delete",
  Write_Full_Product_name: "Write Full Product name",
  There_is_no_products: "There is no products",
  This_name_already_exists: "This name already exists",
  Product_Name_Required: "Product Name Required",
  Image_Doest_Not_Exist: "Image Doest Not Exist",
  image_name_is_required: "image name is required",
  SUBMIT_YOUR_ZIP_FILE: "SUBMIT YOUR ZIP FILE",
  Product_Image: "Product Image",

  Pvp: "Pvp",
  Iva_Percent: "VAT Percent",
  Data_Out: "Data Out",
  Data_In: "Data In",
  Promo_Act: "Promo Act",
  Przlist: "Przlist",
  Exclusion_Man: "Exclusion Man",
  Um: "Um",
  Codice_Ean: "Ean code",
  Raggr03_Originale: "Raggr03 Originale",
  Raggr02_Originale: "Raggr02 Originale",
  Raggr01_Originale: "Raggr01 Originale",
  Totale_Smk: "Totale Smk",
  Codice: "Code",

  dec: "Dec",
  in_stock: "In stock",
  max_value: "Max value",
  min_value: "Min value",

  active_product: "ACTIVE PRODUCT",
  disable_product: "DISABLED PRODUCT",

  product_export_failed: "Product Export Failed",
  all_sales_points: "All Sales Points",

  //PRODUCT_INFO_CATALOGUE_MODULE
  Product_Name: "Product Name",
  Product_Code: "Product Code",
  upload_at_least_one_item: "upload at least one item ",
  Upload_Image_1: "Upload Image 1",
  Upload_Image_2: "Upload Image 2",
  Upload_Image_3: "Upload Image 3",
  Upload_Image_4: "Upload Image 4",
  Upload_Image_5: "Upload Image 5",
  Upload_Video: "Upload Video URL (Embedded)",
  Upload_Audio: "Upload Audio URL (Embedded)",
  Code: "Code",
  Product_Code: "Product Code",
  Product_Details: "Product Details",
  Product_Info_updated_successfully: "Product Info updated successfully",

  //ECOMMERCE_MODULE
  Order_List: "Order List ",
  Order_Moving: "Order Moving",
  Move: "Move",
  Order_No: "Order No.",
  Order_From: "Order From",
  Order_Date: "Order Date",
  Delivery_Date: "Delivery Date",
  sub_total: "Sub Total",
  Status: "Status",
  Action: "Action",
  CLIENT: "CLIENT",
  Sale_Point: "Sale Point",
  Order_No: "Order No",
  Order_Datetime: "Order Datetime",
  Order_Note: "Order Note",
  Order_Payment: "Order Payment ",
  CARD_PAYMENT: "CARD PAYMENT",
  CASH_ON_DELIVERY: "CASH ON DELIVERY",
  PAYPAL_PAYMENT: "PAYPAL PAYMENT",
  ID: "ID ",
  Name: "Name ",
  Delivery_Type: "Delivery Type",
  Delivery_Date: "Delivery Date",
  Delivery_Slot: "Delivery Slot",
  Change: "Change",
  Change_Info: "Change Info",
  Products_Chat: "Products / Chat",
  CHAT_WITH_USER: "CHAT WITH USER",
  ADD_NEW_PRODUCT: "ADD NEW PRODUCT",
  Quantity: "Quantity",
  Note: "Note",
  Send_Message: "Send Message",
  There_is_no_new_chat_notification: "There is no new chat notification",
  Unseen_Order_List_Messages: "Unseen Order List Messages",
  Fails_To_Send_Message: "Fails To Send Message",
  CONFIRM_ORDER: "CONFIRM ORDER",
  There_is_no_orders: "There is no orders",
  click_to_select_a_date: "click to select a date",
  Yes_Change_it: "Yes, Change it!",
  You_can_change_it_later: "You can change it later",
  Product_Codice_Ean_doesnt_exist_in_store: "Product Codice Ean doesn\'t exist in store",
  PLEASE_SELECT_DELIVERY_SLOT_TIME: "PLEASE SELECT DELIVERY SLOT TIME",
  You_have: "You have ",
  hand_written_products_first_remove_this_products: " hand written products, first remove this product/s",
  Order_Details: "Order Details",
  ORDER: "ORDER",
  Choose: "Choose",
  Available_slot: "Available slot",
  Total_Price: "Total Price",
  EDIT: "EDIT",
  Product_Quantity: "Product Quantity",
  chat_message: "chat message",

  Order_Total_Amount: "Order Total Amount",
  Shipping_Cost: "Shipping Cost",
  Current_extra_cost: "Current extra cost",
  Order_Total_Final_Amount: "Order Total Final Amount",
  SUBMIT_COUPONS: "SUBMIT COUPONS",
  Subtotal: "Subtotal",
  Shipping: "Shipping",
  Grand_Total: "Grand Total",
  Invoice: "Invoice",
  Order: "Order",
  sorry_can_not_move_order: "Sorry, you can't move this order",
  these_products_out_of_stock: "These products out of stock",
  You_can_move_the_order_only_for_buying_request: "You can move the order only for buying request.",
  You_can_move_the_order: "You can move the order",
  Successfully_match_all_items: "Successfully match all items",
  EXTRA_COST: "EXTRA COST",
  extra_cost_validation_msg: "Extra cost must be less then total order amount, when Subtract",

  Product_Coupons: "Product Coupons",
  Code: "Code",
  Percentage: "Percentage",
  Discount_Euro: "Discount Euro",
  Current_Amount: "Current Amount",
  Partial_Use: "Partial Use",

  Send_Push_Notification: "Send Push Notification",

  //LAST_MINUTE_OFFER_MODULE
  For_Which_Sales_Point: "For Which Sales Point",
  PDF: "PDF",
  Organization: "Organization",
  Item_Type: "Item Type",
  Offer_List: "Offer List",
  Booking_List: "Booking List",
  Product_List: "Product List",
  Last_Minute_Offer_Details: "Last Minute Offer Details",
  There_is_no_offers: "There is no offers",
  Last_Minute_Offer_Created_Succesfully: "Last Minute Offer Created Succesfully",
  Last_Minute_Offer_Of_This_Sale_Point_Already_Exists: "Last Minute Offer Of This Sale Point Already Exists !!",

  Sales_Points_List_Loading_Problem: "Sales Points List Loading Problem",
  UPLOAD_IMAGE_PDF: "UPLOAD IMAGE/PDF",
  Last_Minute_Offer_List: "Last Minute Offer List",
  Last_Minute_Offer_Update: "Last Minute Offer Update",

  lastMinuteOfferBookingItems: "Last Minute Offer Booking Items",
  requestId: "Request ID: ",
  back: "Back",
  productName: "Product Name",
  quantity: "Quantity",
  noOffers: "No offers available.",
  date: "Date",
  salesPoint: "Sales Point",
  totalItem: "Total Item",
  total: "Total",
  details: "Details",
  lastMinuteOfferProductDetails: "Last Minute Offer Product Details",


  //COMMUNICATION_MODULE
  Message_Send: "Message Send",
  Message: "Message",
  Message_List: "Message List ",
  Send_By: "Send By",
  YOUR_MESSAGE: "YOUR MESSAGE ",
  There_is_no_messages: "There is no messages",
  Message_Update_Successfully: "Message Update Successfully",

  Message_Update: "Message Update",

  //INSTITUTIONAL_FLYERS
  Flyer_Starting_Date: "Flyer Starting Date",
  Flyer_Ending_Date: "Flyer Ending Date",
  upload_flyer_image: "upload flyer image",
  Institutional_Flyer_List: "Institutional Flyer List",
  There_is_no_flyer_available: "There is no flyer available",
  Flyer_Has_Been_Created_Successfully: "Flyer Has Been Created Successfully",
  Please_upload_the_file: "Please upload the file",
  Flyer_Has_Been_Updated_Successfully: "Flyer Has Been Updated Successfully",

  Image: "Image",
  Flyer_Update: "Flyer Update",

  //SALES_POINT_MODULE
  Branch_Info_Upload: "Branch Info Upload",
  Branch_Service_Upload: "Branch Service Upload",
  Sales_Points_List: "Sales Points List ",
  Sales_Points: "Sales Points",
  Zone: "Zone",
  Comune: "Comune",
  BRANCH_DETAILS: "BRANCH DETAILS",
  Telephone: "Telephone",
  Region: "Region",
  BRANCH_DELIVERY_SLOT: "BRANCH DELIVERY SLOT",
  HOME_DELIVERY_SLOT: "HOME DELIVERY SLOT",
  Order_Collect_Time: "Order Collect Time",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thrusday: "Thrusday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  Order_Close_Time: "Order Close Time",
  Collect_from_store_slot: "Collect from store slot",
  BRANCH_SERVICES: "BRANCH SERVICES",
  YES: "YES",
  NO: "NO",
  There_is_no_branches: "There is no branches",
  Service_status_is_changed: "Service status is changed",
  Make_sure_to_fill_all_the__red_marking_fields: "Make sure to fill all the  red marking(*) fields",
  Sale_point_updated_sucessfully: "Sale point updated sucessfully",
  Sales_Points_Details: "Sales Points Details",
  Respo1: "Respo1",
  Respo2: "Respo2",
  Respo3: "Respo3",
  Make_sure_to_fill_all_the_fields_with_red_sign: "Make sure to fill all the  fields with red(*) sign",
  Sales_Points_Update: "Sales Points Update",
  select_province_first: "select province first",
  select_region_first: "select region first",

  //DELIVERY_SLOT_MODULE
  Upload_sales_points_delivery_time_table: "Upload sales points delivery time table",
  Add_Special_Date_and_Delivery_Slots_for_Stores: "Add Special Date and Delivery Slots for Stores",
  Select_Store_by_shop_Code: "Select Store by shop Code",
  None_Selected: "None Selected",
  select_all: "select all",
  Special_Date: "Special Date",
  Special_Dates: "Special Dates",
  click_to_select_date: "click to select date",
  ADD: "ADD",
  SUBTRACT: "SUBTRACT",
  Home_Delivery: "Home Delivery",
  Delivery_From_Store: "Delivery From Store",
  Home_Delivery_Slot: "Home Delivery Slot",
  Store_Delivery_Slot: "Store Delivery Slot",
  Home_Delivery_Same_Day_Delivery_Order_Closing_Time: "Home Delivery Same Day Delivery Order Closing Time",
  Store_Delivery_Same_Day_Delivery_Order_Closing_Time: "Store Delivery Same Day Delivery Order Closing Time",

  Choose_delivery_availability_for_home_delivery_and_delivery_from_store: "Choose delivery availability for home delivery and delivery from store",
  Please_add_one_or_multiple_dates: "Please add one or multiple dates",
  Please_select_one_or_multiple_store_from_your_store_list: "Please select one or multiple store from your store list",
  Selected_Dates: "Selected Dates",


  //TICKET_MANAGEMENT_MODULE
  Ticket_Number: "Ticket Number",
  Phone_Number: "Phone Number",
  Category: "Category",
  OPEN: "OPEN",
  CLOSE: "CLOSE",
  Ticket_Created: "Ticket Created ",
  Contact_No: "Contact No",
  Ticket_Messages: "Ticket Messages",
  ADMIN: "ADMIN",
  USER: "USER",
  Reply: "Reply",
  There_is_no_tickets: "There is no tickets",
  Ticket_doesnt_exist: "Ticket doesn't exist",
  Tickets_List: "Tickets List",
  Ticket_Number_is_required: "Ticket Number is required",
  Tickets_Details: "Tickets Details",
  TICKET: "TICKET",
  Tickets: "Tickets",
  Tickets_Categories: "Tickets Categories",
  Category_ID: "Category ID",
  Ticket_Category_Update: "Ticket Category Update",
  Ticket_Category_Create: "Ticket Category Create",

  //NEWS_MODULE
  News_Create: "News Create ",
  News_Title: "News Title",
  News_Description: "News Description",
  Upload_News_Image: "Upload News Image",
  News_List: "News List ",
  News_Image: "News Image",
  News_Details: "News Details ",
  News_Has_Been_Created_Successfully: "News Has Been Created Successfully",
  There_is_no_news: "There is no news",
  News_Has_Been_Updated_Successfully: "News Has Been Updated Successfully",
  new_description_required: "news description required",
  News_Update: "News Update",


  // USER_POINT MODULE
  User_Points_List: "User Points List ",
  Total_Earning_Points: "Total Points",
  Current_Points: "Current Points",
  Pending_Points: "Pending Points",
  Approved_Points: "Approved Points",
  Rejected_Points: "Rejected Points",
  User_Points_Details: "User Points Details",
  target_points: "Target Points",
  referral_points_list: "Referral Points List",
  referral_points_details: "Referral Points Details",
  Invitation_Points: "Invitation Points",
  Use_Referral_Code: "Use Referral Code",
  Accepting_Points: "Accepting Points",
  Referred_Users: "Referred Users",
  Start_date_must_be_less_then_end_date: "Start date must be less then end date",

  Points_Conversion_List: "Points Conversion List",
  Points_Conversion_Requests: "Points Conversion Requests",
  Points_To_Coupon: "Points To Coupon",
  Terms_and_conditions: "Terms and conditions",
  Generation: "Order Refer Generation",
  Referral_Points: "Referral App Download",
  Generation_label: "Generation label",
  Earning_Points: "Earning Points",
  generation_points_details: "Generation Points Details",

  // all status
  Earned_From_Order: "Earned From Order",
  Coupons_Request_Pending: "Coupons Request Pending",
  Coupons_Request_Approved: "Coupons Request Approved",
  Coupons_Request_Rejected: "Coupons Request Rejected",
  Approved: 'Approved',
  Rejected: 'Rejected',
  Pending: 'Pending',
  None: 'None',
  
  // button label
  Approve: "Approve",
  Reject: "Reject",
  Approve_All: "Approve All",
  Reject_All: "Reject All",

  // msg
  Approved_All_Request: "Approved All Request",
  Rejected_All_Request: "Rejected All Request",
  Approved_Request: "Approved Request",
  Rejected_Request: "Rejected Request",

  // placeholder
  Select_Status: 'Select Status',
  Select_Start_Date: 'Select Start Date',
  Select_End_Date: 'Select End Date',

  // label
  Points: 'Points',
  Date: 'Date',
  Requested_Points: 'Requested Points',
  Total_Request: "Total Request",
  User_ID: "User ID",


  //EVENT_MODULE
  Event_Title: "Event Title",
  Event_Description: "Event Description",
  Event_Starting_Date: "Event Starting Date",
  Event_Ending_Date: "Event Ending Date",
  Event_Location: "Event Location",
  Upload_Event_Image: "Upload Event Image",
  Event_List: "Event List ",
  Event_Image: "Event Image",
  Event_Start: "Event Start ",
  Event_End: "Event End",
  Upcoming_Event: "Upcoming Event",
  Ongoing_Event: "Ongoing Event",
  Event_Details: "Event Details ",
  There_is_no_event_of_your_seleted_type: "There is no event of your seleted type",
  ALL: "ALL",
  ONGOING: "ONGOING",
  UPCOMING: "UPCOMING",
  Event_Create: "Event Create",
  Event_Update: "Event Update",
  Event_Type: "Event Type",
  Free: "Free",
  Paid: "Paid",
  Event_Ticket_Price_Amount: "Event Ticket Price Amount",
  Payment_Method: "Payment Method",
  Cash: "Cash",
  Online_Payment: "Online Payment",
  Both: "Both",
  Please_select_event_type: "Please select event type",
  Please_select_payment_method: "Please select payment method",

  //REVIEWS_MODULE
  Review_Create: "Review Create",
  REVIEW_SET_NAME: "REVIEW SET NAME",
  Your_Questions: "Your Questions",
  write_your_question_here: "write your question here",
  Reviews_List: "Reviews List ",
  Review_Set_Name: "Review Set Name",
  Review_Details: "Review Details ",
  Reviews_Update: "Reviews Update ",
  If_delete_this_review_set_then_your_questions_with_rating_will_also_be_deleted_and_wont_be_able_to_revert_this: "If delete this review set then your questions with rating will also be deleted and won't be able to revert this!",
  At_a_time_you_can_active_deactive_one_review_set_and_other_review_sets_will_be_deactivated: "At a time you can active/deactive one review set and other review sets will be deactivated",
  users_rate_this_question: "users rate this question",
  not_yet: "not yet",
  Review_Questions_Has_Been_Created_Successfully: "Review Questions Has Been Created Successfully",
  Review_Questions_Has_Been_Updated_Successfully: "Review Questions Has Been Updated Successfully",

  //ADVANCE_PROFILING_MODULE
  SURVEY: "SURVEY",
  SURVEY_SET_NAME: "SURVEY SET NAME",
  Survey_Set_List: "Survey Set List ",
  Set_Name: "Set Name",
  Create_Date: "Create Date",
  Last_Edited_Date: "Last Edited Date",
  TOTAL_PARTICIAPTION: "TOTAL PARTICIAPTION ",
  QUESTIONS: "QUESTIONS",
  SURVEY_QUESTIONS: "SURVEY QUESTIONS",
  SURVEY_QUESTIONS_TYPE: "SURVEY QUESTIONS TYPE",
  Single: "Single",
  Multiple: "Multiple",
  survey_options: "survey options",
  VIEW_ALL_QUESTIONS: "VIEW ALL QUESTIONS",
  UPDATE_SET_NAME: "UPDATE SET NAME",
  UPDATE_YOUR_QUESTIONS: "UPDATE YOUR QUESTIONS",
  DELETE_QUESTION_NO: "DELETE QUESTION NO ",
  Update_Question: "Update Question",
  maximum_4_options: "maximum 4 options",
  All_survey_questions_and_informations_will_be_removed_and_you_wont_be_able_to_revert_this: "All survey questions and informations will be removed and you won't be able to revert this!",
  At_a_time_you_can_active_deactive_one_survey_set_and_other_survey_sets_will_be_deactivated: "At a time you can active/deactive one survey set and other survey sets will be deactivated",
  There_is_no_survey_question_set: "There is no survey question set",
  SET_NAME_EMPTY: "SET NAME EMPTY",

  users: "users",
  All_questions_and_options_will_be_removed_and_you_wont_be_able_to_revert_this: "All questions and options will be removed and you won't be able to revert this",
  Question_No: "Question No",
  Active_Inactive: "Active/Inactive",
  Inactive: "Inactive",
  Active_Survey: "Active Survey",
  No_survey_is_active: "No survey is active",
  Answer_Type: "Answer Type",
  Create_Question: "Create Question",
  View_Questions: "View Questions",
  TOTAL_QUESTION: "TOTAL QUESTION",
  VIEW_ALL_PARTICIPANTS: "View all participants",
  Create_New_Question: "Create New Question",
  Answers: "Answers",
  Reward_Points: "Reward Points",
  Target_Amount: "Target Amount",
  All_Participants: "All Participants",
  back_to_the_survey_list: "Back to the survey list",

  //NOTICE_MODULE
  Notice: "Notice",
  Last_update: "Last update",
  published_by: "published by",
  delete: "delete",
  update_notice: "update notice",
  Notice_Sent_Successfully: "Notice Sent Successfully ",

  //CONTACT_MODULE
  Contact_Details: "Contact Details",
  Head_Office_Contact: "Head Office Contact",
  Customer_Care_Contact: "Customer Care Contact",
  Complaints_Center_Contact: "Complaints Center Contact",
  Information_Center_Contact: "Information Center Contact",
  ADD_CONTACT: "ADD CONTACT",
  Contact_Address: "Contact Address",
  Fax: "Fax ",
  Facebook_URL: "Facebook URL",
  Instagram_URL: "Instagram URL",
  Twitter_URL: "Twitter URL",
  linkedin_url: "linkedin url ",

  Create_New_Contact_for: "Create New Contact for",

  //PRIVACY_AGREEMENT_MODULE
  Privacy_Agreement: "Privacy Agreement",
  Privacy_Text: "Privacy Text",
  Update_Privacy: "Update Privacy",
  Please_Write_Your_Privacy: "Please Write Your Privacy",
  user_list: "Accepted users list",
  Privacy_List: "Privacy List",
  active_privacy: "Active privacy policy",
  deactivated_privacy: "Deactivated privacy policy",

  //MANAGE_USER_ACCOUNT_MODULE
  Users_Account_Delete_Requests: "Users Account Delete Requests",

  //SETTINGS_MODULE
  SETTINGS_MODULE: "SETTINGS MODULE",
  Max_Item_For_Shopping_List: "Max Item For Shopping List",
  Max_Item_For_Favourite_List: "Max Item For Favourite List",
  Max_Item_For_Essential_List: "Max Item For Essential List",

  Settings: "Settings",

  paypal_client_id: "Paypal Client id",
  paypal_secret_key: "Paypal Secret key",
  stripe_private_key: "Stripe Private key",
  stripe_publishable_key: "Stripe Publishable key",
  change_paypal_key: "Change Paypal Payment Key",
  change_stripe_key: "Change Stripe Payment Key",

  Coupon_Conversion_Rate: 'Coupon Conversion Rate',
  Max_Min_Request: 'Maximum / Minimum Request',
  Expiry_Date: 'Expiry Days',

  Maximum_Point: 'Maximum Point',
  Maximum_Euro: 'Maximum Euro',
  Minimum_Point: 'Minimum Point',
  Minimum_Euro: 'Minimum Euro',
  No_Max_Limit: 'No Maximum Limit',
  No_Min_Limit: 'No Minimum Limit',
  No_Days_Limit: 'No Days Limit',

  Point: 'Point',
  Euro: 'Euro',
  Days: 'Days',

  create_new_user: "Create New User",
  Create_New_Branch_User: "Create New Branch User",
  Update_Branch_User: "Update Branch User",
  Change_User_Password: "Change User Password",
  Password_changed_successfully: "Password changed successfully",
  Password_dose_not_match: "Password dose not match",
  User_update_successfully: "User update successfully",
  Username_already_exist: "Username already exist. Please Try another username",
  branch_code: "Branch Code",

  product_attributes: "Product Attributes",
  attributes: "attributes",

  referral_points: "Referral Points",
  Reward_points_for_sending_invitation: "Reward points for sending invitation",
  Reward_points_for_using_referral_code: "Reward points for using referral code",

  //ABOUT_MODULE
  About_Us: "About Us",
  Our_Team: "Our Team",
  List: "List",
  Designation: "Designation",
  View_Position: "View Position",
  No_Team_Members: "No Team Members",
  Create_Team_Member: "Create Team Member",
  Edit_Team_Member: "Edit Team Member",
  Upload_Image: "Upload Image",

  Blog: "Blog",
  No_Blog_Found: "No Blog Found",
  Edit_Blog: "Edit Blog",
  Upload_New_Blog: "Upload New Blog",
  Upload_Image_Video: "Upload Image/Video",
  OR: "OR",
  Embed_Video_Link: "Embed Video Link",
  Paste_your_video_link_here: "Paste your video link here:",
  Gallery: "Gallery",
  No_Gallery_Item_Found: "No Gallery Item Found",
  Edit_Item: "Edit Item",
  Upload_New_Item: "Upload New Item",
  Title: "Title",

  //QUICK CHAT
  Quick_Chat: "Quick Chat",
  Chat: "Chat",
  Send: "Send",
  Customer_List: "Customer List",
  New: "New",
  min_ago: "min ago",
  hrs_ago: "hrs ago",
  days_ago: "days ago",
  months_ago: "months ago",
  years_ago: "years ago",

  Delivery_Address: "Delivery Address",
  Address_1: "Address 1",
  Address_2: "Address 2",
  No_Delivery_Address_Found: "No Delivery Address Found",

  Total_Items: "Total Items",

  //HELP & VARIOUS
  help_and_various: "Help & Various",
  module_name: "Module Name",
  tutorial: "Tutorial",

  //APP VIDEO TOUR
  app_video_tour: "Virtual Tours",
  iframe_link: "Iframe Link",

  //Coupons
  coupons: "Coupons",
  coupon_upload: "Coupons Upload",
  coupon_list: "Coupons List",
  COUPONS_UPLOAD: "COUPONS UPLOAD",
  coupon_search: "Coupon Search",
  coupon_id_fidelity: "ID Fidelilty",
  coupon_prodotto: "Prodotto",
  coupons_reparto: "Rreparto ",
  coupon_sottoreparto: "Sub Department",
  coupon_Codice_EAN: "Codice EAN",
  coupon_Disc_percentage: "Disc_%",
  coupon_Disc_euro: "Disc_€",
  coupons_expiration: "Expiration",
  coupons_used: "used",
  There_are_no_coupons: "There are no coupons",
  reset_all_coupons: "Reset all coupons",
  all_coupons_reset_alert: "This action cannot be undone. Are you sure you want to reset all coupons ?",
  export_coupons_before_reset_alert: "Do you want to export current coupons list before reset all coupons?",
  yes_reset_all: "Yes, reset all",
  reset: "Reset all",
  Select_Department: "Select Department",
  Select_Sub_Department: "Select Sub Department",
  Select_Expiration_Date: "Select Expiration Date",
  barcode: "Barcode",

  //Delivery Cost
  deliver_cost: "Delivery Cost",
  free: "Free",
  delivery_cost_free_for_order_amount: "Delivery cost free for order amount",
  fixed: "Fixed Price",
  percentage: "Percentage",
  select_one_delivery_cost_value: "You didn't select your delivery cost !",
  shipping_cost_validation_msg: "Please select one or both options from FIXED SHIPPING COST or PERCENTAGE SHIPPING COST. It will be applicable when the order price will be less than the minimum order price amount.",
  Common_Shipping_Cost: "Common Shipping Cost",
  Shipping_cost_free_for_minimum_order_amount: "Shipping cost free for minimum order amount",
  Upload: "Upload",
  Uploading: "Uploading",
  file_upload_successful: "File Upload Successful",
  update_successful: "Update Successful",

  //Shopping Points
  shopping_points: "Shopping eCoins",
  shopping_points_amount: "Shopping eCoins Per Amount",
  shopping_points_total: "eCoins",

  //HOME_MODULE
  TOTAL_APP_USERS: "TOTAL APP USERS",
  ANDROID_IOS: "ANDROID & IOS",
  FIDELITY_CARD_REQUEST: "FIDELITY CARD REQUEST",
  today: "today",
  ORDER_RECEIVED: "ORDER RECEIVED",
  last_7_days: "last 7 days",
  FIND_PRODUCT_INFO: "FIND PRODUCT INFORMATION",
  FIND_ORDER_INFO: "FIND ORDER INFORMATION",
  CUSTOMER_ANALYSIS: "Customer Analysis",
  PRODUCT_SALES: "PRODUCT SALES",
  Sales_for: "Sales for",
  TOP_5_PRODUCTS: "TOP 5 PRODUCTS",
  based_on_selling: "based on selling ",
  APP_DOWNLOADED: "APP DOWNLOADED",
  new_user_for: "new user for",
  family_memeber: "FAMILY MEMBER",
  Jan: "Jan",
  Feb: "Feb ",
  Mar: "Mar",
  Apr: "Apr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Aug",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dec",

  TOTAL_QUESTIONS_PER_REVIEW_SET: "TOTAL QUESTIONS PER REVIEW SET",
  TOTAL_QUESTIONS_PER_ADVANCE_PROFILING_SET: "TOTAL QUESTIONS PER ADVANCE PROFILING SET",
  REPARTO_ORIGINALE: "REPARTO ORIGINALE",
  PROMO_ACT: "PROMO ACT",
  SOTTOREPARTO_ORIGINALE: "SOTTOREPARTO ORIGINALE",
  TOTAL_ORDERS: "TOTAL ORDERS",
  TOTAL_VALUES_OF_ORDERS: "TOTAL VALUES OF ORDERS",
  CASH_ON_DELIVERY: "CASH ON DELIVERY",
  CARD_PAYMENT: "CARD PAYMENT",
  PAYPAL_PAYMENT: "PAYPAL PAYMENT",
  HOME_DELIVERY: "HOME DELIVERY",
  COLLECT_FROM_STORE: "COLLECT FROM STORE",
  TOTAL_ORDER_ON_STATUS: "TOTAL ORDER ON STATUS",
  TOTAL_ORDER_DELIVERED_PER_MONTH: "TOTAL ORDER DELIVERED PER MONTH",
  TOTAL_ORDER_DELIVERED_PER_DAY: "TOTAL ORDER DELIVERED PER DAY",
  TOTAL_VALUE_OF_ORDER_DELIVERED_PER_MONTH: "TOTAL VALUE (€) OF ORDER DELIVERED PER MONTH",
  TOTAL_ORDER_VALUE_DELIVERED_PER_MONTH: "TOTAL ORDER VALUE (€) DELIVERED PER MONTH",
  TOTAL_ORDER_VALUE_DELIVERED_PER_YEAR: "TOTAL ORDER VALUE (€) DELIVERED PER YEAR",
  TOTAL_ORDER_VALUE_DELIVERED_PER_DAY: "TOTAL ORDER VALUE (€) DELIVERED PER DAY",
  TOTAL_VALUE: "TOTAL VALUE",
  TOTAL_ITEM_PROMO_ACT_USED: "TOTAL ITEM PROMO ACT USED",
  TOTAL_ITEM_COUPON_USED: "TOTAL ITEM COUPON USED",
  TOTAL_ORDER_PER_ITEM_NO: "TOTAL ORDER PER ITEM NO.",
  TOTAL_ORDER_PER_SLOT_home_delivery: "TOTAL ORDER PER SLOT (home delivery)",
  TOTAL_ORDER_PER_SLOT_store_delivery: "TOTAL ORDER PER SLOT (store delivery)",

  MALE: 'MALE',
  FEMALE: "FEMALE",
  Select_Year: "Select Year",
  Select_Month: "Select Month",
  Select_province_list: "Select province list",
  Select_sales_point: "Select sales point",
  DATA: 'DATA',

  //Copywright
  Copyright_eCommerceVolutionit: "Copyright eCommerceVolutionit by Big Data Innovation Group 2020/2024. All right reserved",

  //banners
  slider: "Slider",
  banners: "Banners",
  have_to_upload_slider: "Have to upload one slider image",

  //category
  category: "Category",
  select_category: "Select Category",
  serial_no: 'No.',
  upload: "Upload/Change",
  no_category: "There is no category",

  Shipping_cost: "Shipping cost",
  for_minimum_order_amount: "for minimum order amount",
  Shipping_Cost_for_province: "Shipping Cost for province",
  Excel_File_Header_Mismatched: "Excel File Header Mismatched",
  Add_new_shipping_cost: "Add New Shipping Cost",
  Update_shipping_Cost: "Update Shipping Cost",
  Amount_nearest_Euro: "Amount (to the nearest Euro)",
  shop_code: "Shop Code",
  shipping_cost: "Shipping Cost",

  product_points: "Product Points",
  product_points_list: "Product Points List",
  Product_Points_Update: "Product Points Update",

  Ticket_Category_Create: "Create Ticket Category",

  Points_By_Referring: "Points By Referring",
  Self_Installed_Points: "Self Installed Points",
  Total_Points: "Total Points",
  Total_Installed_By_Refer_Code: "Total Installed By Refer Code",

  earn_from_survey: "Earn from survey",
  earn_from_product: "Earn from product",
  earn_from_referral_invitation: "Earn from referral invitation",
  earn_from_using_referral_code: "Earn from using referral code",
  earn_from_target_shopping: "Earn from target shopping",

  //MLM
  mlm: "MLM",
  refer_conversion_point: "Refer Generation Point",
  first_refer: "1st Refer",
  order_per_amount: 'Order Per Amount',
  second_refer: "2nd Refer",
  third_refer: "3rd Refer",

  firstReferRequired: "First refer must have values.",
  both_fields_must_be_filled: "Both fields must be filled",
  fillPrevious: "You must fill the previous refer before this one.",
  
};