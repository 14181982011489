import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { withRouter } from 'react-router-dom';
import RouteAuthentication from '../../navigators/RouteAuthentication';
import LogoutService from '../../services/LogoutService'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import '../../common/common.css'
import LN from '../../common/Language'
import './Header.css'

const styles = {
    arrow_icon: {
        width: 20,
        marginRight: 20
    },
    logout_icon: {
        width: 30,
        marginRight: 30
    }
}
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show_icon: true,
            lg: LN.LANGUAGE_NAME
        };
    }

    async componentDidMount() {

        if (window.innerWidth < 650) {
            this.setState({ show_icon: !this.state.show_icon })
        }

        let current_lang = await localStorage.getItem('selected_lang');
        if (current_lang) {
            this.setState({ lg: current_lang });
        }

    }
    change_icon = () => {
        this.props.sidebar_show_hide();
        this.setState({ show_icon: !this.state.show_icon })
    }
    async logout() {

        let logout = await LogoutService.logout();

        await localStorage.removeItem('userIsLoggedIn');
        await localStorage.removeItem('login_id');
        await localStorage.removeItem('login_token');
        await localStorage.removeItem('role_id');
        await localStorage.removeItem('client_code');

        this.props.on_logout_function();
        RouteAuthentication.logout(() => { this.props.history.push("/"); });
    }

    async notifications() {
    }

    SET_LANGUAGE = async (val) => {
        await localStorage.setItem('selected_lang', val);

        LN.LANGUAGE_NAME = val;
        this.setState({ lg: val });

        this.props.language_change_notify(val);
        this.props.history.push("/refresh");
    }

    render() {
        if (window.location.pathname != '/' && window.location.pathname != '/forgot_password' && window.location.pathname != '/forgot_password/change_password') {
            return (
                <div >
                    <Navbar bg="warning" expand="lg" className="border-bottom" id="header">
                        {
                            this.state.show_icon === true ?
                                <img src={require('../../assets/images/arrow_close.png')} alt="bigdata_logo" style={styles.arrow_icon}
                                    onClick={() => this.change_icon()} />
                                :
                                <img src={require('../../assets/images/arrow_show.png')} alt="bigdata_logo" style={styles.arrow_icon}
                                    onClick={() => this.change_icon()} />
                        }

                        <Navbar.Brand href="#home" >{LN[this.state.lg].bigdata}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Link href="#home"></Nav.Link>
                                <Nav.Link href="#link"></Nav.Link>
                            </Nav>

                            <div style={{ color: 'black', marginRight: 50 }}>
                                <h4  >{LN[this.state.lg].welcome}, {LN[this.state.lg].ADMIN}</h4>
                            </div>

                            <div style={{ color: 'green', marginRight: 20 }}>
                                <a href="https://bigdatainnovationgroup.it/" target="_blank">{LN[this.state.lg].help}</a>
                            </div>

                            {/* <p style={{color: 'red'}}>0</p>
                            <img src={require('../../assets/images/bell.png')} alt="bigdata_logo" style={styles.logout_icon}
                                onClick={() => this.notifications()} /> */}

                            <DropdownButton title={this.state.lg} variant="" className="mr-3" onSelect={(lg_val) => this.SET_LANGUAGE(lg_val)}>
                                <Dropdown.Item eventKey={"IT"}>{"Italiano"}</Dropdown.Item>
                                <Dropdown.Item eventKey={"EN"}>{"English"}</Dropdown.Item>
                                <Dropdown.Item eventKey={"ES"}>{"Española"}</Dropdown.Item>
                            </DropdownButton>

                            <img src={require('../../assets/images/logout.png')} alt="bigdata_logo" style={styles.logout_icon}
                                onClick={() => this.logout()} />


                        </Navbar.Collapse>
                    </Navbar>

                </div>
            );
        } else {
            return (
                <DropdownButton title={this.state.lg} variant="dark" style={{ backgroundColor: '#3C78A9', textAlign: 'right' }} onSelect={(lg_val) => this.SET_LANGUAGE(lg_val)}>
                    <Dropdown.Item eventKey={"IT"}>{"Italiano"}</Dropdown.Item>
                    <Dropdown.Item eventKey={"EN"}>{"English"}</Dropdown.Item>
                    <Dropdown.Item eventKey={"ES"}>{"Española"}</Dropdown.Item>
                </DropdownButton>
            );
        }

    }
}

export default withRouter(Header);